<template>
  <v-row>
    <v-dialog
      v-model="dialogOpen"
      persistent
    >
      <v-card>
        <!-- The toolbar at the top of the pop-up -->
        <v-app-bar
          flat
          dark
          color="accent"
        >
          <v-btn
            icon
            dark
            @click="closeClick"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ expDetail.title }}</v-toolbar-title>

          <v-spacer />

          <!-- The delete/restore buttons -->
          <v-btn
            v-if="expDetail.hidden === '0'"
            text
            @click="openDeleteDialog"
          >
            <v-icon class="pr-1">
              mdi-delete
            </v-icon>
            Delete
          </v-btn>

          <v-btn
            v-else
            text
            @click="requestExpRestore"
          >
            <v-icon class="pr-1">
              mdi-delete-restore
            </v-icon>
            Restore
          </v-btn>

          <!-- The edit button -->
          <v-btn
            text
            @click="editClick"
          >
            <v-icon class="pr-1">
              mdi-square-edit-outline
            </v-icon>
            Edit
          </v-btn>
        </v-app-bar>

        <!-- The content of the dialog itself -->
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :items="expDetailArray"
                :headers="headers"
                hide-default-footer
                :loading="loading"
              >
                <template
                  slot="items"
                  slot-scope="props"
                >
                  <td>{{ props.item.key }}</td>
                  <td>{{ props.item.value }}</td>
                </template>
              </v-data-table>
            </v-col>

            <!--<v-flex xs12>-->
            <!--<div-->
            <!--v-if="detailsToShow.length > 0"-->
            <!--class="exp_details"-->
            <!--&gt;-->
            <!--<template v-for="(detail, index) in detailsToShow">-->
            <!--<div-->
            <!--:key="'key_' + detail + '_index_' + index"-->
            <!--:class="'exp_' + detail"-->
            <!--&gt;-->
            <!--{{ expDetail[detail] }}-->
            <!--</div>-->
            <!--</template>-->
            <!--</div>-->

            <!--<v-list v-else>-->
            <!--<template v-for="(detail, index) in expDetail">-->
            <!--<v-list-tile-->
            <!--:key="'key_' + detail + '_index_' + index"-->
            <!--class="v-card-->
            <!--mb-1"-->
            <!--&gt;-->
            <!--<v-list-tile-content>{{ detail }}</v-list-tile-content>-->
            <!--</v-list-tile>-->
            <!--</template>-->
            <!--</v-list>-->
            <!--</v-flex>-->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteExpDialog"
      max-width="500px"
      persistent
    >
      <v-card
        tile
        class="popup-dialog"
      >
        <v-app-bar
          flat
          dark
          color="warning"
        >
          <v-toolbar-title>Are you sure you want to delete experiment {{ expID }}?</v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <div>
            Only press delete if this experiment if none of the LEDs in the
            entire experiment were powered up at any time.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            text
            rounded
            :disabled="loading"
            @click="requestExpDelete"
          >
            Delete
          </v-btn>
          <v-btn
            text
            rounded
            @click="deleteExpDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { EventBus } from '../scripts/event_bus'

export default {
  name: 'ExpDetailDialog',
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    expID: {
      type: Number,
      required: true,
      default: 0
    },
    detailsToShow: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data () {
    return {
      loading: false,
      expDetail: {},
      expDetailArray: [],
      headers: [
        { text: 'Key', value: 'key' },
        { text: 'Value', value: 'value' }
      ],
      deleteExpDialog: false
    }
  },
  watch: {
    dialogOpen: function (nowOpen, wasOpen) {
      if (nowOpen && !wasOpen) {
        this.refreshDetails()
      }
    }
  },
  methods: {
    refreshDetails: function () {
      if (!this.loading) {
        this.loading = true

        this.$root.$data.si.getExpDetail([
          { key: 'exp_id', value: this.expID }
        ], resp => {
          this.loading = false

          this.expDetail = resp.experiment[0]
          this.expDetailArray = Object.entries(this.expDetail).map(([key, value]) => ({ key, value }))
        })
      }
    },
    editClick: function () {
      // Close the detail dialog
      this.closeClick()
      // Route to the run experiment dialog
      this.$router.push({ name: 'run_exp', params: { exp_id: this.expID } })
    },
    closeClick: function () {
      this.$emit('closeClicked')
    },
    openDeleteDialog: function () {
      this.deleteExpDialog = true
    },
    requestExpDelete: function () {
      console.log('Requested deletion of exp id', this.expID)

      this.postToDeleteScript(true)
    },
    requestExpRestore: function () {
      console.log('Requested to restore exp id', this.expID)

      this.postToDeleteScript(false)
    },
    postToDeleteScript: function (hide) {
      if (!this.loading) {
        this.loading = true

        this.$root.$data.si.postExpDelete([
          { key: 'exp_id', value: this.expID },
          { key: 'action', value: (hide ? 'hide' : 'unhide') }
        ], response => {
          this.loading = false

          // console.log(response)

          if (Number(response.success) === 1 && response.action === 'done') {
            // Tell the user it was a success
            EventBus.$emit('info', ('Exp ' + this.expID + ' was ') + (hide ? 'deleted' : 'restored') + ' successfully.')
            // Close the dialogs
            if (hide) {
              this.deleteExpDialog = false
              this.closeClick()
            }
            // Refresh the data of the loaded page
            EventBus.$emit('refresh')
            // And of this dialog
            this.refreshDetails()
          } else {
            console.log('Could not ' + (hide ? 'delete' : 'restore') + ' exp id', this.expID)

            this.deleteExpDialog = false
            EventBus.$emit('error', {
              title: 'Could not ' + (hide ? 'delete' : 'restore'),
              message: response.success ? response.error_message : 'This experiment ' + (hide ? 'has already been deleted' : 'is not deleted')
            })
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
